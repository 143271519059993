import React from "react";
import { useNavigate,Link } from 'react-router-dom';
import Arrow_icon_right from '../../Assets/Icons/Arrow_Small_Right.svg';
import { Breadcrumbs, Typography } from "@mui/material";
import NAAC_DATA from "../../Assets/JSON/NAAC/Naac.json"


const NAACComponent = () =>{

  const navigate = useNavigate();
   const navigation_url = (path) => {
       
          navigate("/naac/" + path.Id + "/" + path.pathurl);
    }
    return (
        <section className="container py-5">
            <Breadcrumbs separator="›" sx={{ width: "100%", display: "block",marginBottom: "10px" }} aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to="/">
          <b>Home</b>
        </Link>
        <Typography underline="hover" color="text.primary"><b>NAAC</b></Typography>
      </Breadcrumbs>
            <div className="mb-4 text-start">
                <Typography variant="h6"><b>NAAC Details</b></Typography>
            </div>
            <div>
                {NAAC_DATA && NAAC_DATA.map((items, index) => {
                    return(
                    <div className="d-flex mb-3" key={index}>
                    <img src={Arrow_icon_right} alt="Arrow Icon" />
                    <Typography variant="subtitle1" className="m-0 cursor-pointer" onClick={() => {navigation_url(items)}}> {items.title}</Typography>
                    </div>
                    )
                } )}
            </div>
        </section>
    );
}

export default NAACComponent