import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import NAAC_Data from "../../Assets/JSON/NAAC/Naac.json";
import { Button, Typography } from "@mui/material";

const Naacpdf = () => {
  const [selectedDocData, setSelectedDocData] = useState(null);
  const { Id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const filteredData = NAAC_Data.find((docdata) => docdata.id === Id);
    setSelectedDocData(filteredData);
  }, [Id]);

  const handleBackClick = () => {
    navigate('/naac');
  };

  return (
    <div className="container py-4">
      <Button variant="contained" onClick={handleBackClick}>Go Back</Button>
      {selectedDocData && (
        <div>
        <Typography variant="h5" className="text-center">{selectedDocData.filename}</Typography>
        <iframe
          src={selectedDocData.pathname}
          title="PDF Viewer"
          width="100%"
          height="600px"
          style={{ border: 'none' }}
        />
        </div>
      )}
      {!selectedDocData && (
        <p>No data found.</p>
      )}
    </div>
  );
};

export default Naacpdf;