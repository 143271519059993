import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';

const Working_Commitee = () => {
    const workingCommittee = [
        {
            "Sno": 1,
            "id": 1,
            "name": "Dr shekar Matta, Principal",
            "designation": "Chairperson"
        },
        {
            "Sno": 2,
            "id": 2,
            "name": "Mrs. P. Prasunna Sundari, HOD Economics",
            "designation": "Member"
        },
        {
            "Sno": 3,
            "id": 3,
            "name": "   Mr. N Venkatesh, HOD Chemistry Department",
            "designation": "Member"
        },
        {
            "Sno": 4,
            "id": 4,
            "name": "Dr. R Vijender Kumar, HOD Public Admin Dept",
            "designation": "Member"
        },
        {
            "Sno": 5,
            "id": 5,
            "name": "Mr. N Hari Kumar, HOD- Physics Dept",
            "designation": "Member"
        }
    ];
    return (
        <section className="container py-4">
            <div>
                <Typography variant="h6" component="div" sx={{ my: 2 }}>
                    WORKING COMMITTEE
                </Typography>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>S.NO</TableCell>
                            <TableCell align="">Name of the Member</TableCell>
                            <TableCell align="">Designation</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {workingCommittee.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>
                                <TableCell align="">{row.name}</TableCell>
                                <TableCell align="">{row.designation}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </section>
    )
}

export default Working_Commitee